import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalserviceService {
  public static customer_id:string;
  public static first_name:string;
  public static last_name:string;
  public static email_id:string;
  constructor() {          
    if(localStorage.getItem('currentUser')){
      GlobalserviceService.customer_id = JSON.parse(localStorage.getItem('currentUser')).Data.customer_id;
      GlobalserviceService.first_name = JSON.parse(localStorage.getItem('currentUser')).Data.first_name;
      GlobalserviceService.last_name = JSON.parse(localStorage.getItem('currentUser')).Data.last_name;
      GlobalserviceService.email_id = JSON.parse(localStorage.getItem('currentUser')).Data.email_id;
    }else{
      GlobalserviceService.customer_id = JSON.parse(localStorage.getItem('corporateUser')).Data.customer_id;
      GlobalserviceService.first_name = JSON.parse(localStorage.getItem('corporateUser')).Data.first_name;
      GlobalserviceService.last_name = JSON.parse(localStorage.getItem('corporateUser')).Data.last_name;
      GlobalserviceService.email_id = JSON.parse(localStorage.getItem('corporateUser')).Data.email_id;
    }
  }
}
