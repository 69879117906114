// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl : 'https://devapi.instaglobaltravel.com',
  baseUrl : 'https://www.instaglobaltravel.com',
  cdnUrl : 'https://www.instaglobaltravel.com',
  pgUrl : 'http://dev.instaglobaltravel.com',
  frontPath : 'front/',
  customerPath : 'customer/',
  corporatePath : 'corporate/',
  visaPath : 'visa/',
  domain_id: '1',
  lang: 'en',
  razorpay:{
    store_id:'ID:D87uVI8QaOyTwh',
    key_id:'rzp_test_z9vU9kj0HYWbzC',
    secret_key_id:'jG4vgIKZonaZUVpz0IwcNXbZ',
    pay_url:''
  },
  payPalApiKey:'Adzi_qn_F8M--xXSIKHRILcl9nZS-4Hvgx2hmgJhGkdav-iFz71ppVe3mD81_2Etj-wNGp-AlcmhU5px',
  stripepublishableKeygoogle:'pk_test_51ImGFBSE347zpdOmm1IHWpklcvGfVpfG7d0lRcF8Z829Bs0wYVO6EZanxbim4W4VnAzNcMizL8QdLsiStsMeGjmp00kWh5tqKX',
  gpaymerchantId:'12345678901234567890',
  merchantName:'Example Merchant',
  environmentgpay: 'TEST'

 };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
