import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class CustomvalidationService {
  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      //const regex = new RegExp('^(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
      const regex = new RegExp('^.{8,}$');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }
  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];
      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }
      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }
      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }
  CheckDate(travel_date: string, date_of_birth: string, passport_expiry_date: string) {
    return (applForm: FormGroup) => {
      const travelDateControl = applForm.controls[travel_date];
      const dateOfBirthControl = applForm.controls[date_of_birth];
      const passportExpiryControl = applForm.controls[passport_expiry_date];
      if (!dateOfBirthControl.value || !passportExpiryControl.value || !travelDateControl.value) {
        return null;
      }
      if (dateOfBirthControl.errors && !dateOfBirthControl.errors.dobMismatch && !passportExpiryControl.errors.passMismatch && !passportExpiryControl.errors.setMismatch) {
        return null;
      }
      let tDate = new Date(travelDateControl.value.split("-").reverse().join("-")).getTime();
      let dDate = new Date(dateOfBirthControl.value.split("-").reverse().join("-")).getTime();
      let pDate = new Date(passportExpiryControl.value.split("-").reverse().join("-")).getTime();
      //travelDateControl = {value:tDate};
      if (tDate < dDate) {
        dateOfBirthControl.setErrors({ dobMismatch: true });
      }
      else {
        dateOfBirthControl.setErrors(null);
      }
      if (dDate > pDate) {
        passportExpiryControl.setErrors({ passMismatch: true });
      } else {
        passportExpiryControl.setErrors(null);
      }
      if (pDate < tDate) {
        passportExpiryControl.setErrors({ setMismatch: true });
      } else {
        passportExpiryControl.setErrors(null);
      }
    }
  }
  CheckAnniversayDate(date_of_birth: string, anniversary_date: string) {
    return (editProfileForm: FormGroup) => {
      const dateOfBirthEditProfileControl = editProfileForm.controls[date_of_birth];
      const annivesaryDateEditProfileControl = editProfileForm.controls[anniversary_date];      

      if (dateOfBirthEditProfileControl.value && annivesaryDateEditProfileControl.value) {             
           
        if (dateOfBirthEditProfileControl.errors && !dateOfBirthEditProfileControl.errors.dobMismatch) {
          return null;
        }
        let ddDate = new Date(dateOfBirthEditProfileControl.value).getTime();
        let aaDate = new Date(annivesaryDateEditProfileControl.value).getTime();
        if (aaDate < ddDate) {          
          annivesaryDateEditProfileControl.setErrors({ dobMismatch: true });
        }
        else {
          annivesaryDateEditProfileControl.setErrors(null);
        }
      }
    }
  }
}