import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrontendService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute) { }

    saveEnquiry(name: string, email: string,country_isd_code:any,contact_no: number, message: string) {
      return this.http.post<any>(`${environment.apiUrl}/contactus/save`, { name, email, country_isd_code, contact_no,message})
      .pipe(map(enquiry => {
          return enquiry;
      }));
    }

    saveSubscription(email: string) {
      return this.http.post<any>(`${environment.apiUrl}/contactus/subscription`, {email}).pipe(map(getSubscription => {
          return getSubscription;
      }));
    }

    unSubscription(email: string) {
      return this.http.post<any>(`${environment.apiUrl}/contactus/unSubscription`, {email}).pipe(map(getUnSubscription => {
          return getUnSubscription;
      }));
    }

    getAllBlogs(limit: number, offset: number) {
      return this.http.post<any>(`${environment.apiUrl}/Blog/getBlog`, {limit,offset}).pipe(map(getBlogs => {
          return getBlogs;
      }));
    }

	getBlogsDestinationCategoryWise(lang: string, destinationId: string, cat_id:string) {
      return this.http.post<any>(`${environment.apiUrl}/Blog/getBlog`, {lang,destinationId,cat_id}).pipe(map(getBlogs => {
          return getBlogs;
      }));
    }

    getBlogCategory(lang: string) {
      return this.http.get<any>(`${environment.apiUrl}/Blog/category`).pipe(map(blogCategory => {
          return blogCategory;
      }));
    }

    getBlogs(lang: string,destination_name: string,blog_slug: string){

      return this.http.post<any>(`${environment.apiUrl}/Blog/getBlogDetails`, {lang,destination_name,blog_slug}).pipe(map(singleBlog => {
            return singleBlog;
      }));
     }


    getVideos(lang: string,video_id: string,limit: Number,offset: Number){
      return this.http.post<any>(`${environment.apiUrl}/media/video`, {lang,video_id,limit,offset}).pipe(map(videos => {
        return videos;
      }));
    }


    getFaqs(lang: string,destinationId: string,nationalityId: string,faqId: string){
      return this.http.post<any>(`${environment.apiUrl}/faq/getFaq`, {lang,destinationId,nationalityId,faqId}).pipe(map(faqs => {
            return faqs;
      }));
     }


	getTrackApplication(referenceNo: string){
      return this.http.post<any>(`${environment.apiUrl}/customer/VisaByReferanceNo`, {referenceNo} ).pipe(map(trackApplication => {
            return trackApplication;
      }));
     }




	getAllBlogsByDestination(limit: number, offset: number, destinationId: any) {
      return this.http.post<any>(`${environment.apiUrl}/Blog/getBlog`, {limit,offset,destinationId}).pipe(map(getBlogs => {
          return getBlogs;
      }));
    }

	getVideosByDestination(lang: string,video_id: string,limit: Number,offset: Number, destinationId: any){
      return this.http.post<any>(`${environment.apiUrl}/media/video`, {lang,video_id,limit,offset,destinationId}).pipe(map(videos => {
        return videos;
      }));
    }

	getFaqsByDestination(lang: string,destinationId: string,nationalityId: string,faqId: string){
      return this.http.post<any>(`${environment.apiUrl}/faq/getFaq`, {lang,destinationId,nationalityId,faqId}).pipe(map(faqs => {
            return faqs;
      }));
    }

    saveMeetandgreet(application_ref: any, application_id: any,airport_code:any,terminals_name:any,flight_type:any,adults_member:any,children_member:any,infants_member:any,arrival_date:any,arrival_time: any,flight_name:any, flight_number: any,drop_location:any) {
      return this.http.post<any>(`${environment.apiUrl}/customer/meetandgreetSave`, { application_ref, application_id,airport_code,terminals_name,flight_type,adults_member,children_member,infants_member, arrival_date,arrival_time,flight_name,flight_number,drop_location})
      .pipe(map(enquiry => {
          return enquiry;
      }));
    }


    mngstaticData(application_ref: any, application_id: any) {
      return this.http.post<any>(`${environment.apiUrl}/customer/mngstaticData`, { application_ref, application_id})
      .pipe(map(enquiry => {
          return enquiry;
      }));
    }


    updateCustomerAppDownloadSource(sourceData: any){
      return this.http.post<any>(`${environment.apiUrl}/customer/updateCustomerAppDownloadSource`, {sourceData}).pipe(map(sData => {
		    return sData;
      }));
    }




}