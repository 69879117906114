import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthencticateService} from './service/authencticate.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authencticateService: AuthencticateService,
    private route: ActivatedRoute
) {}

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return false;
  // }

  canActivate(route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authencticateService.currentUserValue;
    if (user) {     
        // authorised so return true
        return true;
    }
	
	if(localStorage.getItem('corporateUser')){
		return true;
	}

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/customer/login'], { relativeTo: this.route });
    return false;
}
  
}
